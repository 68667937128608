import Macy from 'macy'

Macy({
    container: '#advantage-group',
    margin: {
        x: 56,
        y: 40,
    },
    columns: 2,
    breakAt: {
        760: {
            margin: 24,
            columns: 1,
        },
    },
})
